































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Validations } from 'vuelidate-property-decorators'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import MultiselectV2 from '@/app/ui/components/MultiselectV2/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import Loading from '@/app/ui/components/Loading/index.vue'
import TrashIcon from '@/app/ui/assets/trash_icon_alt.vue'
import AddIcon from '@/app/ui/assets/add_icon.vue'
import PayrollTextInput from '../../../components/PayrollTextInput/index.vue'
import ModalConfirm from '../../../components/Modals/ModalConfirm/index.vue'
import ModalSuccess from '../../../components/Modals/ModalSuccess/index.vue'
import CheckBox from '../../../components/CheckBox/index.vue'
import controller from '@/app/ui/controllers/PayrollController'
import { LocalStorage } from '@/app/infrastructures/misc'
import { Tier } from '@/domain/entities/Payroll'
import routeController from '@/app/ui/controllers/RouteController'
import { LetterCode } from '@/domain/entities/Route'

interface Dropdown {
  value: string
  label: string
}

interface IBonusFee {
  captainBonus?: Dropdown[]
  captainBonusFee?: number
  captainBonusOptions?: string[]
  insurance: boolean
  percentageBonus: boolean
  percentageBonusData: IPercentageData[]
}

interface IBonusData {
  minStt: number
  bonus: number
}

interface IPercentageData {
  adjustment: number
  bonusMaxLimit: number
  percentage: number
}

interface BonusFeePayload {
  captain_bonus?: string[]
  captain_bonus_fee?: number
  captain_bonus_options?: string[]
  insurance?: boolean
  percentage_bonus?: boolean
  percentage_bonus_data?: BonusPercentagePayload[]
}

interface BonusDataPayload {
  min_stt: number
  bonus: number
}

interface BonusPercentagePayload {
  adjustment: number
  bonus_max_limit: number
  percentage: number
}

interface isValidation {
  [k: string]: unknown
}

interface PeriodRule {
  basicFee: {
    calculationMethod: Array<string>
    productType: Array<string>
    multiplyType: Array<string>
    rules: Array<Record<string, string | Array<Tier>>>
  }
  basicFeeId: number
  bonusFee: {
    captainBonus: Array<string>
    captainBonusFee: number
    captainBonusOptions: Array<string>
    insurance: boolean
    performanceBonus: boolean
    performanceBonusData: Array<IBonusData>
    percentageBonus: boolean
    percentageBonusData: Array<IPercentageData>
    pickupBonus: boolean
    pickupBonusData: Array<IBonusData>
  }
  bonusFeeId: number
}

@Component({
  mixins: [validationMixin],
  components: {
    TextInput,
    Button,
    MultiselectV2,
    Loading,
    TrashIcon,
    AddIcon,
    PayrollTextInput,
    ModalConfirm,
    ModalSuccess,
    DropdownSelect,
    CheckBox,
  },
})
export default class BonusMechanism extends Vue {
  controller = controller
  routeController = routeController
  currentPeriod = ''
  isBonusPickUpAll3LCActive = false
  isBonusCaptainAll3LCActive = false
  selectedOrigin = ''
  bonusId = ''
  modalConfirmVisible = false
  modalSuccessVisible = false

  originOptions: Dropdown[] = []

  form: IBonusFee = {
    captainBonus: [],
    captainBonusFee: 0,
    captainBonusOptions: [],
    insurance: false,
    percentageBonus: false,
    percentageBonusData: [],
  }

  created(): void {
    this.currentPeriod = LocalStorage.getLocalStorage('selectedPeriod')
    this.fetchData()
  }

  private fetchData(): void {
    controller.getDetail({
      id: this.$route.params.periodId,
      page: parseInt(<string>this.$route.params.page),
    })
  }

  private fetchLetterCodeList(): void {
    this.routeController.getLetterCodes()
  }

  private onSearchOrigin(query: string): void {
    let res: Array<Dropdown> = []

    if (query === '') {
      this.originOptions = routeController.letterCodes.map(route => {
        return {
          label: <string>route.lc,
          value: <string>route.lc,
        }
      })
    }

    routeController.letterCodes.forEach(route => {
      if ((<string>route.lc).toUpperCase().includes(query.toUpperCase())) {
        res.push({
          label: <string>route.lc,
          value: <string>route.lc,
        })
      }
    })

    this.originOptions = res
  }

  private mapOrigin(name: 'captainBonus'): Dropdown[] {
    const res: Dropdown[] = []

    if (
      controller.dataPeriodRule.bonusFee &&
      controller.dataPeriodRule.bonusFee[name]
    ) {
      controller.dataPeriodRule.bonusFee[name]?.forEach(city => {
        const cityOption = this.originOptions.find(
          origin => origin.value === city
        )
        if (cityOption) {
          res.push(cityOption)
        }
      })

      this.form[name] = res
    }

    return res
  }

  private checkTierIsUnique(
    value: IBonusData,
    values: Array<IBonusData>
  ): boolean {
    let isUnique = true
    const firstIndex = values.findIndex(v => v.minStt === value.minStt)

    values.forEach((bonus: IBonusData, index: number) => {
      if (index !== firstIndex) {
        if (bonus.minStt === value.minStt) {
          isUnique = false
        }
      }
    })

    return isUnique
  }

  private checkTierIsHeavier(
    value: IBonusData,
    values: Array<IBonusData>
  ): boolean {
    let isHeavier = true
    const valueIndex = values.findIndex(v => v.minStt === value.minStt)

    if (valueIndex === 0) {
      return isHeavier
    }

    const prevWeight = values[valueIndex - 1].minStt
    const currWeight = value.minStt

    if (currWeight !== undefined && prevWeight !== undefined) {
      if (currWeight < prevWeight) {
        isHeavier = false
      }
    }

    return isHeavier
  }

  private tierValidation(
    bonusType:
      | 'percentageBonusData'
      | 'performanceBonusData'
      | 'pickupBonusData',
    tierIndex: number,
    field: string
  ): string {
    const bonus = this.$v.form[bonusType]
    if (bonus) {
      const bonusItem = bonus.$each[tierIndex]
      if (bonusItem) {
        const isUnique = bonusItem.isUnique
        const isHeavier = bonusItem.isHeavier
        const isEmpty = bonusItem[field]
        if (isEmpty.$invalid) {
          return 'Wajib diisi'
        } else if (!isUnique) {
          return 'Angka sudah ada di dalam range'
        } else if (!isHeavier) {
          return 'Tidak boleh lebih kecil dari kolom sebelumnnya'
        }
      }
    }

    return ''
  }

  private onSubmit(): void {
    let bonusCaptainPayload: string[] = []

    if (this.isBonusCaptainAll3LCActive) {
      bonusCaptainPayload = ['3LC']
    } else if (this.form.captainBonus) {
      bonusCaptainPayload = this.form.captainBonus.map(item => item.value)
    }

    const payload: { id: string; rules: BonusFeePayload } = {
      id: this.bonusId,
      rules: {
        captain_bonus: bonusCaptainPayload,
        captain_bonus_fee: this.form.captainBonusFee,
        captain_bonus_options: this.form.captainBonusOptions,
        insurance: this.form.insurance,
        percentage_bonus: this.form.percentageBonus,
        percentage_bonus_data: this.form.percentageBonusData.map(item => {
          return {
            adjustment: item.adjustment,
            bonus_max_limit: item.bonusMaxLimit,
            percentage: item.percentage,
          }
        }),
      },
    }

    controller.updatePeriodBonusFee(payload)
  }

  @Validations()
  validations(): isValidation {
    const validation: Record<string, Record<string, unknown>> = {
      form: {
        captainBonusFee: { required },
        percentageBonus: {},
      },
    }

    if (!this.isBonusCaptainAll3LCActive) {
      validation.form.captainBonus = { required }
    }

    if (this.form.percentageBonus) {
      validation.form.percentageBonusData = {
        $each: {
          percentage: { required },
          adjustment: { required },
        },
      }
    }

    return validation
  }

  @Watch('controller.dataPeriodRule')
  setBonusFee(data: PeriodRule): void {
    const bonusFee = data.bonusFee
    this.bonusId = String(data.bonusFeeId)

    const res: IBonusFee = {
      captainBonus: bonusFee.captainBonus.map(lc => ({ label: lc, value: lc })),
      captainBonusFee: bonusFee?.captainBonusFee,
      captainBonusOptions: bonusFee?.captainBonusOptions,
      insurance: bonusFee?.insurance || false,
      percentageBonus: bonusFee?.percentageBonus || false,
      percentageBonusData:
        (bonusFee?.percentageBonusData as IPercentageData[]) || [],
    }

    if (
      bonusFee?.captainBonus?.length === 1 &&
      bonusFee.captainBonus[0] === '3LC'
    ) {
      this.isBonusCaptainAll3LCActive = true
      res.captainBonus = []
    }

    this.form = res
    this.fetchLetterCodeList()
  }

  @Watch('routeController.letterCodes')
  onLetterCodesChanged(data: LetterCode[]): void {
    this.originOptions = data.map(route => {
      return {
        label: <string>route.lc,
        value: <string>route.lc,
      }
    })
  }

  @Watch('isBonusCaptainAll3LCActive')
  resetBonusCaptain3LC(data: boolean): void {
    if (data) {
      this.form.captainBonus = []
    }
  }

  @Watch('controller.isEditPeriodBonusSuccess')
  setModalSuccessVisible(data: boolean): void {
    this.modalConfirmVisible = false
    if (data) {
      this.modalSuccessVisible = true
      this.fetchData()
      controller.setIsChanged(false)
      controller.setIsPeriodBonusSuccess(false)
    }
  }
}
