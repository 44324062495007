var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-56 text-bgMatterhorn"},[(_vm.controller.isLoadingDetail || _vm.routeController.isLoadingLetterCode)?_c('div',{staticClass:"flex justify-center"},[_c('Loading')],1):_c('div',{staticClass:"flex flex-col gap-4"},[_c('h2',{staticClass:"font-bold"},[_vm._v("Periode: "+_vm._s(_vm.currentPeriod))]),_c('div',{staticClass:"flex flex-col items-start gap-4 rounded-lg border-2 border-gray-3 p-4"},[_c('span',{staticClass:"font-bold"},[_vm._v("Bonus Kapten")]),_c('div',{staticClass:"flex flex-col gap-4 w-full"},[_c('div',{staticClass:"flex gap-4"},[_c('CheckBox',{attrs:{"isChecked":_vm.isBonusCaptainAll3LCActive},on:{"click":function () {
                _vm.isBonusCaptainAll3LCActive = !_vm.isBonusCaptainAll3LCActive
              }}}),_c('span',{staticClass:"text-sm"},[_vm._v("ALL 3LC")])],1),_c('span',{staticClass:"col-span-1 text-sm font-bold"},[_vm._v("3LC")]),_c('div',{staticClass:"flex flex-col gap-4 w-3/5"},[_c('div',[_c('MultiselectV2',{staticClass:"w-full",attrs:{"placeholder":"Masukkan 3LC","variant":"tags","tagsDisplayAmount":5,"options":_vm.originOptions,"disabled":_vm.isBonusCaptainAll3LCActive},on:{"input":function () { return _vm.controller.setIsChanged(true); },"search":function (e) { return _vm.onSearchOrigin(e); }},model:{value:(_vm.form.captainBonus),callback:function ($$v) {_vm.$set(_vm.form, "captainBonus", $$v)},expression:"form.captainBonus"}}),_c('span',{staticClass:"text-sm text-red-4"},[_vm._v(_vm._s(!_vm.isBonusCaptainAll3LCActive && _vm.$v.form.captainBonus.$invalid ? '3LC Wajib diisi' : ''))])],1),_c('div',{staticClass:"flex flex-col gap-4 w-full"},[_c('span',{staticClass:"col-span-1 text-sm font-bold"},[_vm._v("Uang Bonus Kapten")]),_c('PayrollTextInput',{staticClass:"max-w-none w-full",attrs:{"id":"bonus-captain-amount","name":"bonusCaptainAmount","type":"currency","errorMessage":_vm.$v.form.captainBonusFee.$invalid ? 'Wajib diisi' : '',"placeholder":"15000"},on:{"input":function () { return _vm.controller.setIsChanged(true); }},model:{value:(_vm.form.captainBonusFee),callback:function ($$v) {_vm.$set(_vm.form, "captainBonusFee", $$v)},expression:"form.captainBonusFee"}})],1)])])]),_c('div',{staticClass:"flex flex-col gap-4"},[_c('div',{staticClass:"flex gap-4"},[_c('div',{on:{"click":function () { return _vm.controller.setIsChanged(true); }}},[_c('CheckBox',{attrs:{"isChecked":_vm.form.percentageBonus},on:{"click":function () {
                _vm.form.percentageBonus = !_vm.form.percentageBonus
              }}})],1),_c('span',{staticClass:"text-sm"},[_vm._v("Bonus Performance")])]),_c('div',{staticClass:"flex flex-col items-start gap-4 rounded-lg border-2 border-gray-3 p-4"},[_c('span',{staticClass:"font-bold"},[_vm._v("Bonus Performance")]),_c('div',[_vm._m(0),_vm._l((_vm.form.percentageBonusData),function(item,index){return _c('div',{key:index},[_c('div',{staticClass:"flex max-w-168 gap-4"},[_c('PayrollTextInput',{staticClass:"w-full",attrs:{"id":"bonus-percentage","name":"bonusPercentage","type":"number","fieldSuffix":"%","disabled":!_vm.form.percentageBonus,"errorMessage":_vm.form.percentageBonus &&
                  _vm.$v.form.percentageBonusData.$each[index].percentage.$invalid
                    ? 'Wajib diisi'
                    : '',"placeholder":"10"},on:{"input":function () { return _vm.controller.setIsChanged(true); }},model:{value:(item.percentage),callback:function ($$v) {_vm.$set(item, "percentage", $$v)},expression:"item.percentage"}}),_c('PayrollTextInput',{staticClass:"w-full",attrs:{"id":"percentage-adjustment","name":"percentageAdjustment","type":"currency","disabled":!_vm.form.percentageBonus,"errorMessage":_vm.form.percentageBonus &&
                  _vm.$v.form.percentageBonusData.$each[index].adjustment.$invalid
                    ? 'Wajib diisi'
                    : '',"placeholder":"15000"},on:{"input":function () { return _vm.controller.setIsChanged(true); }},model:{value:(item.adjustment),callback:function ($$v) {_vm.$set(item, "adjustment", $$v)},expression:"item.adjustment"}})],1)])})],2)])]),_c('div',{staticClass:"flex gap-4"},[_c('div',{on:{"click":function () { return _vm.controller.setIsChanged(true); }}},[_c('CheckBox',{attrs:{"isChecked":_vm.form.insurance},on:{"click":function () {
              _vm.form.insurance = !_vm.form.insurance
            }}})],1),_c('span',{staticClass:"text-sm"},[_vm._v("Life & Health Insurance")])]),_c('Button',{staticClass:"w-32 py-3 px-6 text-xs",attrs:{"customPadding":"","disabled":_vm.$v.form.$invalid},on:{"click":function () {
          _vm.modalConfirmVisible = true
        }}},[_c('span',[_vm._v("Simpan")])])],1),_c('ModalConfirm',{attrs:{"visible":_vm.modalConfirmVisible,"title":"Konfirmasi Penambahan Data","description":"Pastikan data yang diisi sudah sesuai dengan data aslinya"},on:{"cancel":function () {
        _vm.modalConfirmVisible = false
      },"action":function () { return _vm.onSubmit(); }}}),_c('ModalSuccess',{attrs:{"visible":_vm.modalSuccessVisible,"title":"Data Berhasil Ditambahkan","description":""},on:{"close":function () {
        _vm.modalSuccessVisible = false
      }}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex max-w-168 mb-2 items-end"},[_c('span',{staticClass:"w-full text-sm font-bold"},[_vm._v("Persentase")]),_c('span',{staticClass:"w-full text-sm font-bold"},[_vm._v("Adjustment")])])}]

export { render, staticRenderFns }